


<template>
  <div class="container" style="">
    <div class="row text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="25">

      <h1 data-aos="flip-left" data-aos-duration="2000" data-aos-delay="50" class=" mt-2 mb-3 display-5"  style="font-family: 'Pixelify Sans', sans-serif; color:rgb(203, 250, 208)"><strong>Blume Plugins</strong></h1>

      <div class="mt-1 mb-4 d-none d-sm-block">
      <a target="_blank" href="https://www.instagram.com/blumeplugins/"><img class="col-1 mx-4 img-fluid" style="width:4%;" src="./assets/instagram.png" alt="Instagram">  </a>
      <a target="_blank" href="https://twitter.com/BlumePlugins"> <img class="col-1 mx-4 img-fluid" style="width:4%;" src="./assets/twitter.png" alt="X"></a>
      <a target="_blank" href="https://discord.gg/8XJQpNeZkY"><img class="col-1 mx-4 img-fluid" style="width:4%;" src="./assets/discord.png" alt="Discord"></a>
      <a target="_blank" href="mailto:blumeplugins@gmail.com"><img class="col-1 mx-4 img-fluid" style="width:4%;" src="./assets/gmail.png" alt="Gmail"></a>
      
      </div>


      <div class="mt-3 mb-1 d-block d-sm-none">
      <a target="_blank" href="https://www.instagram.com/blumeplugins/"><img class="col-1 mx-4 img-fluid" style="width:8%;" src="./assets/instagram.png" alt="Instagram">  </a>
      <a target="_blank" href="https://twitter.com/BlumePlugins"> <img class="col-1 mx-4 img-fluid" style="width:8%;" src="./assets/twitter.png" alt="X"></a>
      <a target="_blank" href="https://discord.gg/8XJQpNeZkY"><img class="col-1 mx-4 img-fluid" style="width:8%;" src="./assets/discord.png" alt="Discord"></a>
      <a target="_blank" href="mailto:blumeplugins@gmail.com"><img class="col-1 mx-4 img-fluid" style="width:8%;" src="./assets/gmail.png" alt="Gmail"></a>
      
      </div>

      <div class="btn-group mb-4 justify-content-center mt-4" role="group" aria-label="Basic example">
    
  <button style="color:rgb(203, 250, 208)" @click="this.butActive = true ; this.rpActive = false; " type="button"  :class="[butActive ? 'btn-success ' : 'btn-secondary']" class="btn-sm col-2 mx-3 mb-4 d-none d-sm-block"> <strong>Minecraft But</strong>  </button>
  <button style="color:rgb(203, 250, 208)" @click="this.butActive = false ; this.rpActive = true;" type="button" :class="[rpActive ? 'btn-success' : 'btn-secondary']" class="btn-sm col-2 mx-3 mb-4 d-none d-sm-block"> <strong>Minecraft Roleplay</strong> </button>
  

  <button  @click="this.butActive = true ; this.rpActive = false; " type="button" :class="[butActive ? 'btn-success' : 'btn-secondary']" class="btn-sm col-4 mx-3 d-block d-sm-none"> <strong>Minecraft But</strong>  </button>
  <button  @click="this.butActive = false ; this.rpActive = true;" type="button" :class="[rpActive ? 'btn-success' : 'btn-secondary']" class="btn-sm col-4 mx-3 d-block d-sm-none "> <strong>Minecraft Roleplay</strong> </button>

    </div>
    <div class="col-4 text-start mt-3 d-none d-sm-block">
    <small v-if="rpActive && !isFiltering" class="h6 text-start" style="color:#dfe1e4"> {{ this.rpVideos.length }} Videos  </small>
    <small v-if="butActive && !isFiltering" class="h6 text-start " style="color:#dfe1e4"> {{ this.butVideos.length }} Videos  </small>
    <small v-if="isFiltering" class="h6 text-start " style="color:#dfe1e4"> {{ this.list.length }} Videos  </small> 
    
    </div>



    <div class="col-3 offset-5 mb-2 d-none d-sm-block">
    <div class="input-group rounded ">
    <input v-model="search" type="search" class="form-control rounded bg-dark text-light" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
    </div>
    
   </div>

   <hr class="text-light mb-0 d-none d-sm-block">



    <div class="col-10 offset-1 mb-1 d-block d-sm-none">
    <div class="input-group rounded ">
    <input v-model="search" type="search" class="form-control rounded bg-dark text-light" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
    </div>
    
 
      <hr class="text-light mb-0">
      
    <div class="col-12 text-center mt-0 d-block d-sm-none">
    <small v-if="rpActive && !isFiltering" class="text-center" style="color:#dfe1e4">{{ this.rpVideos.length }}   Videos </small>
    <small v-if="butActive && !isFiltering" class=" text-center" style="color:#dfe1e4" > {{ this.butVideos.length }} Videos  </small>
    <small v-if="isFiltering" class=" text-center"  style="color:#dfe1e4"> {{ this.list.length }} Videos  </small> 
    </div>
    </div>

 
   
      <div  class="col-md-3 col-4 mt-3 mb-4 my-sm-4 col-md-4 col-lg-3 col-xl-3"  v-for="video in filteredList" :key="video.id">
        <div class="row mx-1" style="margin:auto">
        <div class="col-sm-7 col-md-5 col-lg-5 col-xl-4 text-center text-light bg-success rounded-0 d-none d-sm-block rounded-top ">
          <p class="my-0 text-light" style="font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;"><small> <strong>{{ video.youtuberName }}</strong> </small></p>
        </div>
        <div v-if="video.isNew" class="text-center ms-2 col-3 col-sm-4 col-lg-4 col-xl-3 col-md-5 ms-md-4 ms-lg-2 text-light bg-warning   rounded-top d-none d-sm-block">
          <p class="m-0 text-light d-none d-md-block" style="font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;"><small> <strong>NEW!</strong> </small></p>
          <p class="m-0 text-light d-block d-md-none"><small> <strong>NEW!</strong> </small></p>
        </div>  
        
        </div >
        <div class="row " style="margin:auto">
        <div class="p-0 col-7 text-light bg-success rounded-0 d-block d-sm-none rounded-top">
          <p class="ps-0 my-0 pb-0 text-light " style="font-size:small; font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;"> <small><strong>{{ video.youtuberName }}  </strong></small>  </p>
        </div>

        <div v-if="video.isNew" class="p-0 ms-2 col-4 text-light bg-warning rounded-top d-block d-sm-none">
          <p class="pb-0 p-0 m-0 ps-0 text-light" style="font-size:small; font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;"><small> <strong>NEW!</strong> </small></p>
          </div>  
        </div>
        

        
        <div class="card text-center" style="height: 100%; background:rgb(68, 129, 79);">
            <a style="text-decoration: none;  " class="" :href="'https://www.youtube.com/watch?v=' + video.id" target="_blank">
              <img :src="'https://i.ytimg.com/vi/' + video.id + '/mqdefault.jpg'" :alt="video.title">
            </a>
            <h5 class="card-title card-text my-3 mx-2 d-none d-sm-block text-light" style=" color:rgb(221, 255, 223)"> <strong>{{video.title}}</strong>  </h5>
            <h5 class="card-title card-text my-2 mx-1 d-block d-sm-none text-light" style=" color:rgb(255, 255, 255)"> <strong>{{video.title}}</strong>  </h5>
          
        </div>
      
    </div>

<div class="my-5"></div>

  </div>
</div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();
export default {
  data() {
    return {
      butVideos: [],
      rpVideos: [],
      list: [],
      butActive: true,
      rpActive: false,
      isFiltering: false,
      search: ""
     
    }
  },
  mounted() {
    fetch('./files/All_Plugins.txt')
      .then(response => response.text())
      .then(data => {
        let lines = data.split('\n').filter(line => line.trim() !== '').reverse();
        for (let i = 0; i < lines.length; i += 2) {
          let title = lines[i + 1];
          let fullTitle = lines[i + 1];
          let youtuberName;
          let isNew = false;
          let id;
          if(i < 2)
          {
            isNew = true;
          }
          if(lines[i+1].includes('==')|| lines[i+1].includes('== '))
          {
            youtuberName = lines[i+1].split('==')[1];
            title = lines[i+1].split('==')[0];
          }
          if(lines[i].includes('https://www.youtube.com/watch?v='))
          {
            id = lines[i].split('https://www.youtube.com/watch?v=')[1];
          }
          else if(lines[i].includes('https://youtu.be/'))
          {
            if(lines[i].includes('?si='))
            {
              id = lines[i].split('https://youtu.be/')[1].split('?si=')[0];
            }
            else 
            {
              id = lines[i].split('https://youtu.be/')[1]
            }
          }
          
          this.butVideos.push({
            id: id,
            title: title,
            fullTitle : fullTitle,
            youtuberName: youtuberName,
            isNew: isNew
          });
        }
        
      })
      .catch(error => console.error('Error:', error));




      fetch('./files/All_Scripts.txt')
      .then(response => response.text())
      .then(data => {
        let lines = data.split('\n').filter(line => line.trim() !== '').reverse();
        for (let i = lines.length - 1; i >= 0; i -= 2) {
          let title = lines[i];
          let fullTitle = lines[i];
          let youtuberName;
          let isNew = false;
          let id;
          
          if(lines[i].includes('==')|| lines[i].includes('== '))
          {
            youtuberName = lines[i].split('==')[1];
            title = lines[i].split('==')[0];
          }
          if(lines[i - 1].includes('https://www.youtube.com/watch?v='))
          {
            id = lines[i - 1].split('https://www.youtube.com/watch?v=')[1];
          }
          else if(lines[i - 1].includes('https://youtu.be/'))
          {
            if(lines[i - 1].includes('?si='))
            {
              id = lines[i - 1].split('https://youtu.be/')[1].split('?si=')[0];
            }
            else 
            {
              id = lines[i - 1].split('https://youtu.be/')[1]
            }
          }
          
          
          
          this.rpVideos.push({
            id: id,
            title: title,
            fullTitle : fullTitle,
            youtuberName: youtuberName,
            isNew: isNew
          });
        }
        
      })
      .catch(error => console.error('Error:', error));

 
  },
  computed: {
  filteredList() {
    if(this.butActive)
    {
      if(this.search == '')
      {
        this.isFiltering = false;
        return this.butVideos;

      }
      this.isFiltering = true;
      if(this.search.toLocaleLowerCase() == 'million')
      {
        this.list = this.butVideos.filter(item => item.fullTitle.includes('1,000,000') || item.fullTitle.toLowerCase().includes(this.search.toLowerCase()))
        return this.list
      }
      this.list = this.butVideos.filter(item => item.fullTitle.toLowerCase().includes(this.search.toLowerCase()));
      return this.list;
      
    }
    else if(this.rpActive){
      if(this.search == '')
      {
        this.isFiltering = false;
        return this.rpVideos;
      }
      this.isFiltering = true;
      if(this.search.toLocaleLowerCase() == 'million')
      {
        this.list = this.rpVideos.filter(item => item.fullTitle.includes('1,000,000') || item.fullTitle.toLowerCase().includes(this.search.toLowerCase()))
        return this.list
      }
      this.list = this.rpVideos.filter(item => item.fullTitle.toLowerCase().includes(this.search.toLowerCase()));
      return this.list;
      }
      
    }
  },
  watch:{
    butActive(newValue , oldValue)
    {
      this.search = '';
    },
    rpActive(newValue , oldValue)
    {
      this.search = '';
    }
  }
}


 
</script>


<style>
body {
  scrollbar-width: thin;
  scrollbar-color: #000 #fff;
}
/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.card img { 
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
  
}
@media (max-width: 500px) {
    .card-text {
      font-size: 11px;
      
    }
  }
</style>

